.Draggable-container {
    position: absolute;
    z-index: 100000;
    border: 0.18rem solid #237e8a;
    border-radius: 0.3rem;
    top: 50vh;
    left: 65vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    height: 1.9rem;

    .Controls-container {
        position: absolute;
        right: 0;
        display: inline-block;
        background-color: whitesmoke;
        border-radius: 0.3rem;

        .SmallButton {
            display: inline-block;
            cursor: pointer;
            padding: 4;
        }
    }


    input {
        border-color: transparent;
        background-color: transparent;
        cursor: move;
    }
    
    input:focus {
        border-color: transparent;
        background-color: transparent;
        outline-color: transparent;
    }
}