.PdfWrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.PageControl-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 3rem;

    button {
        border-radius: 0.3rem 0 0 0.3rem;
        height: 70%;
        width: 8%;
        color: black;
        font-size: 1rem;
        font-weight: 500;
        border-color: #ebebeb;
    }

    button:disabled {
        background-color: #a3a3a3;
    }

    #RighButton {
        border-radius: 0 0.3rem 0.3rem 0;
    }

    .PageCounterText {
        font-size: 1.3rem;
        color: whitesmoke;
    }
}