.Main-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  /* width: 100vw; */

  .Top-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 3rem;

    img {
      height: 2.5rem;
    }
  }

  .Bot-container {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    flex-grow: 2;
    width: 100%;
    background-color: #237e8a;

    .Loading-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      h3 {
        color: whitesmoke;
        font-size: 2rem;
      }
    }

    .Options-container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: center;
      background-color: white;
      border-radius: 0.5rem;
      max-width: 25rem;
      max-height: 38rem;
      margin-top: -5rem;

      .Text-container {
        padding: 0 0.5rem;
        max-height: 10rem;
      }

      button {
        width: 23rem;
        height: 2.5rem;
        background-color: whitesmoke;
        border: 0.2rem solid #ebebeb;
        border-radius: 0.5rem;
        font-size: 1rem;
      }

      button:hover {
        background-color: #ebebeb;
        border-color: whitesmoke;
      }

      .Buttons-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 35rem;
        gap: 2rem;

        canvas {
          background-color: whitesmoke;
          width: 22rem;
          height: 6rem;
          margin-bottom: 1rem;
          border-radius: 0.5rem;
        }
      }

      .ActionBtn-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        height: 7rem;

        button {
          color: white;
          font-weight: 600;
        }
        
        #ConfirmBtn {
          background-color: #237e8a;        
        }

        #RestartBtn {
          background-color: #ff0000e0;
        }

        button:hover {
          cursor: pointer;
        }
      }
    }
  }
}